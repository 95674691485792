import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWarehouses(ctx, params) {
      return useJwt.getWarehouses(params).then(response => response);
    },
    getWarehouseDetail(ctx, id) {
      return useJwt.getWarehouseDetail(id).then(response => response);
    },
    createWarehouse(ctx, data) {
      return useJwt.createWarehouse(data).then(response => response);
    },
    updateWarehouse(ctx, data) {
      return useJwt.updateWarehouse(data).then(response => response);
    },
    deleteWarehouses(ctx, data) {
      return useJwt.deleteWarehouses(data).then(response => response);
    },
  },
};
